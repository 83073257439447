/* global.css */

@import 'variables.css';

body {
    margin: 0;
}

body,
body #root .dash-container main,
body p,
body span,
body a,
body button,
body code,
body .MuiTypography-root,
body .MuiButton-root,
body .MuiList-root,
body .MuiMenuItem-root,
body .MuiTable-root,
body .MuiTableCell-root,
body .MuiTableRow-root,
body .MuiInputBase-root,
body .MuiFormControl-root,
body .MuiFormLabel-root,
body .MuiOutlinedInput-root {
    font-family: var(--font-family-regular);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
    font-family: var(--font-family-bold) !important;
    letter-spacing: 0.5;
}

body main fieldset {
    border-color: var(--primary-color) !important;
}

code {
    font-family: var(--font-family-regular);
}

.container {
    margin: 2em 1em;
}

.logo {
    height: 29px;
}

.finallevel {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

tbody tr:hover {
    background-color: var(--grey-100-color);
    cursor: pointer;
}

/* global.css */
.nowrap {
    white-space: nowrap;
}

.containerInside {
    width: '100%';
    padding: 2px;
    border-radius: 2px;
    display: 'flex';
    flex-direction: 'column';
    background-color: var(--white-color);
}
