div .header {
    width: 100%;
    background-color: var(--primary-dark-color);
    height: 90px;
    padding: 8px 0;
}

.containerLogo {
    flex-direction: column;
}

@media (min-width: 769px) {
    div .logo {
        height: 60px;
    }
}

@media (max-width: 769px) {
    div .logo {
        height: 55px;
    }
}

@media (max-width: 280px) {
    div .logo {
        height: 18px;
    }

    .containerLogo {
        padding-left: 0;
    }

    div .header {
        padding: 8px 5px;
    }
}
