div .headerCampainList {
    display: flex;
    flex-direction: row;
    padding-top: 25px;
}

div .containerListItem {
    padding: 0px 45px;
    background-color: var(--grey-50-color);
    border-radius: 8px;
    margin: 20px 15px;
}

div .containerCampain {
    color: var(--primary-dark-color);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 15px;
}

div .containerCampain .cell {
    flex: 1;
}

.wrapper {
    display: flex;
    align-items: center;
    flex-basis: 50%;
}

.containerWorkflow {
    display: flex;
    flex-direction: row;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    min-height: 80vh;
}

@media (max-width: 768px) {
    div .containerListItem {
        padding: 0px;
        margin: 15px 0px;
    }

    .wrapper {
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px 10px;
    }

    .wrapper.firstColunm {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 3px;
        flex-basis: 90%;
    }

    .containerWorkflow::last-child {
        margin-right: 40px;
    }
}

@media (max-width: 280px) {
    div .containerListItem {
        margin: 18px 8px;
    }

    div .containerCampain {
        padding: 10px 8px;
    }

    .containerListItem .containerCampain .wrapper .titleCampain > span {
        font-size: 0.9rem;
    }
}
